
import uniqBy from 'lodash/uniqBy'
import reverse from 'lodash/reverse'
import { Vue, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { message } from 'ant-design-vue'
import { IDepartment, IUser } from '@/utils/types'
import { PlusOutlined, FormOutlined, DeleteOutlined, ClockCircleOutlined } from '@ant-design/icons-vue'
import FireDepartment from '@/services/departments'
import DepartmentFormModal from '@/components/department/DepartmentFormModal.vue'
import UserTagById from '@/components/user/UserTagById.vue'
import { formatDateTime } from '@/utils/helpers'

@Options({
  components: {
    DepartmentFormModal,
    UserTagById,
    PlusOutlined,
    FormOutlined,
    DeleteOutlined,
    ClockCircleOutlined,
  },
  directives: { maska },
})
export default class DepartmentList extends Vue {
  loading = false
  modalVisible = false
  selectedDepartmentId = ''

  searchQuery = ''

  departments: IDepartment[] = []
  departmentsFiltered: IDepartment[] = []

  columns = [
    {
      title: 'Department Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: IDepartment, b: IDepartment) => (a.name?.length || 0) - (b.name?.length || 0),
    },
    {
      title: 'Managers',
      dataIndex: 'managerIds',
      key: 'managerIds',
      slots: { customRender: 'managerIds' },
    },
    {
      title: 'Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      slots: { customRender: 'updatedAt' },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      slots: { customRender: 'action' },
    },
  ]

  formatDateTime = formatDateTime

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  @Watch('departments', { deep: true })
  departmentsChanged() {
    // Get latest update items by reverse
    this.departmentsFiltered = reverse(uniqBy(reverse([...this.departments]), 'id'))
  }

  onAdd() {
    this.selectedDepartmentId = ''
    this.modalVisible = true
  }

  onEdit(id: string) {
    this.selectedDepartmentId = id
    this.modalVisible = true
  }

  async onDelete(id: string) {
    await FireDepartment.delete(id)
    message.success('Deleted successfully')
  }

  async getDepartments() {
    FireDepartment.on((department: IDepartment) => {
      if (department.fireChangeType === 'removed') {
        this.departments = this.departments.filter((item) => item.id !== department.id)
      } else {
        this.departments.push(department)
      }
    })
  }

  beforeDestroy() {
    FireDepartment.off()
    this.departments = []
  }

  created() {
    this.getDepartments()
  }
}
