
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { message } from 'ant-design-vue'
import { IOrganization, IUser } from '@/utils/types'

import FireOrganization from '@/services/organization'
import SearchUserInput from '@/components/user/SearchUserInput.vue'

@Options({
  components: {
    SearchUserInput,
  },
  directives: { maska },
  emits: [],
})
export default class OrganizationBasicInfoForm extends Vue {
  loading = false
  organization: IOrganization = {}

  requiredRule = {
    required: true,
    message: 'This field is required',
    trigger: 'blur',
  }

  form: IOrganization = {
    name: '',
  }

  rules = {
    name: [this.requiredRule],
    ownerIds: [],
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  onSubmitOrganization() {
    this.$refs.formRef
      .validate()
      .then(() => {
        this.doSaveOrganization()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  onAddNewUser(userIds: string[]) {
    if (!this.userInfo.id) {
      return
    }

    this.form.ownerIds = [...userIds, this.userInfo.id]
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  async doSaveOrganization() {
    await FireOrganization.save(this.form)
    message.success('Saved successfully')
  }

  async created() {
    this.organization = (await FireOrganization.get()) || {}

    this.form = { ...this.form, ...this.organization }
  }
}
