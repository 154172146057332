<template>
  <a-modal class="ant-modal--xl" :visible="modalVisible" @cancel="onCancel" :title="modalTitle" :loading="loading">
    <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">
      <a-form-item label="Department Name" name="name">
        <a-input size="large" v-model:value="form.name" autocomplete="name" placeholder="department name" />
      </a-form-item>

      <a-form-item label="Managers" name="users">
        <SearchUserInput @update:onUpdate="onAddNewUser" :canSelectMe="true" :userIds="form.managerIds" />
      </a-form-item>
    </a-form>

    <template #footer>
      <a-button key="back" type="default" @click="onCancel">Cancel</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="onSubmit"> Save </a-button>
    </template>
  </a-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { message } from 'ant-design-vue'
import { IDepartment, IUser } from '@/utils/types'
import SearchUserInput from '@/components/user/SearchUserInput.vue'
import FireDepartment from '@/services/departments'

@Options({
  components: { SearchUserInput },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class DepartmentFormModal extends Vue {
  @Prop({ default: '' })
  id!: string

  @Prop()
  modalVisible!: boolean

  loading = false

  requiredRule = {
    required: true,
    message: 'This field is required',
    trigger: 'blur',
  }

  form: IDepartment = {
    name: '',
    managerIds: [],
  }

  rules = {
    name: [this.requiredRule],
  }

  get modalTitle() {
    return this.isNew ? 'Add new department' : 'Edit department information'
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get isNew() {
    return !this.id
  }

  onAddNewUser(userIds: string[]) {
    this.form.managerIds = userIds
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(() => {
        this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    this.loading = true
    if (!this.id && this.userInfo.id) {
      await FireDepartment.add(this.form)
    } else {
      await FireDepartment.update(this.form)
    }

    message.success('Saved successfully')
    this.loading = false
    this.onCancel()
  }

  async getDepartment() {
    return await FireDepartment.get(this.id)
  }

  @Emit('update:closeModal')
  onCancel() {
    return false
  }

  async created() {
    if (this.id) {
      const department = await this.getDepartment()
      this.form = { ...this.form, ...department }
    }
  }
}
</script>
