
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { SettingOutlined, UserSwitchOutlined } from '@ant-design/icons-vue'
import OrganizationBasicInfoForm from '@/components/organization/OrganizationBasicInfoForm.vue'
import OrganizationLeaveSettingForm from '@/components/organization/OrganizationLeaveSettingForm.vue'
import DepartmentList from '@/components/department/DepartmentList.vue'
import NotFound from '@/components/common/NotFound.vue'
import { Watch } from 'vue-property-decorator'

@Options({
  components: {
    OrganizationBasicInfoForm,
    DepartmentList,
    OrganizationLeaveSettingForm,
    NotFound,
    SettingOutlined,
    UserSwitchOutlined,
  },
  directives: { maska },
  emits: [],
})
export default class OrganizationSetting extends Vue {
  activeTab = ''

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  get isOwner() {
    return this.$store.getters.isOwner
  }

  @Watch('activeTab')
  activeTabChanged() {
    this.goto(this.$router.name, { tab: this.activeTab })
  }

  async mounted() {
    this.activeTab = this.$route.params?.tab || 'basic'
  }
}
