
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { message } from 'ant-design-vue'
import { IOrganization, IOrganizationLeaveSetting, IUser } from '@/utils/types'

import FireOrganization from '@/services/organization'

@Options({
  components: {},
  directives: { maska },
  emits: [],
})
export default class OrganizationLeaveSettingForm extends Vue {
  loading = false
  organization: IOrganization = {}

  requiredRule = {
    required: true,
    message: 'This field is required',
    trigger: 'blur',
  }

  form: IOrganizationLeaveSetting = {
    sendNewLeaveRequestToEmails: [],
    sendApprovedLeaveRequestEmails: [],
    sendApprovedOrderDeviceRequestEmails: [],
  }

  rules = {}

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(() => {
        this.saveSetting()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  async saveSetting() {
    await FireOrganization.save({ id: this.organization.id, leavesSetting: this.form })
    message.success('Saved successfully')
  }

  async created() {
    this.organization = (await FireOrganization.get()) || {}

    this.form = { ...this.form, ...this.organization.leavesSetting }
  }
}
