<template>
  <div v-if="isOwner">
    <div class="pt-5 px-6">
      <div class="flex items-center flex-wrap">
        <h1 class="text-2xl flex-grow">
          <SettingOutlined />
          Organization Setting
        </h1>
        <div>
          <a-button size="large" @click.prevent="goto('staffs')">
            <UserSwitchOutlined />
            Staffs manage
          </a-button>
        </div>
      </div>
      <p class="text-gray-400 text-base">Manage organization infomation</p>
    </div>
    <a-tabs type="card" v-model:activeKey="activeTab" class="px-6">
      <a-tab-pane key="basic" tab="Basic information">
        <OrganizationBasicInfoForm v-if="activeTab === 'basic'" />
      </a-tab-pane>
      <a-tab-pane key="departments" tab="Departments">
        <DepartmentList v-if="activeTab === 'departments'" />
      </a-tab-pane>
      <a-tab-pane key="leaves" tab="Leave setting">
        <OrganizationLeaveSettingForm v-if="activeTab === 'leaves'" />
      </a-tab-pane>
    </a-tabs>
  </div>
  <NotFound v-else />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { SettingOutlined, UserSwitchOutlined } from '@ant-design/icons-vue'
import OrganizationBasicInfoForm from '@/components/organization/OrganizationBasicInfoForm.vue'
import OrganizationLeaveSettingForm from '@/components/organization/OrganizationLeaveSettingForm.vue'
import DepartmentList from '@/components/department/DepartmentList.vue'
import NotFound from '@/components/common/NotFound.vue'
import { Watch } from 'vue-property-decorator'

@Options({
  components: {
    OrganizationBasicInfoForm,
    DepartmentList,
    OrganizationLeaveSettingForm,
    NotFound,
    SettingOutlined,
    UserSwitchOutlined,
  },
  directives: { maska },
  emits: [],
})
export default class OrganizationSetting extends Vue {
  activeTab = ''

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  get isOwner() {
    return this.$store.getters.isOwner
  }

  @Watch('activeTab')
  activeTabChanged() {
    this.goto(this.$router.name, { tab: this.activeTab })
  }

  async mounted() {
    this.activeTab = this.$route.params?.tab || 'basic'
  }
}
</script>
