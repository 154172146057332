<template>
  <a-spin :spinning="loading">
    <div class="container-fluid mt-6">
      <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">
        <a-form-item label="Send new leave request to emails" name="sendNewLeaveRequestToEmails">
          <a-select mode="tags" v-model:value="form.sendNewLeaveRequestToEmails" placeholder="please input"> </a-select>
        </a-form-item>

        <a-form-item label="Send approved leave request to emails" name="sendApprovedLeaveRequestEmails">
          <a-select mode="tags" v-model:value="form.sendApprovedLeaveRequestEmails" placeholder="please input">
          </a-select>
        </a-form-item>

        <a-form-item label="Send approved order device" name="sendApprovedOrderDeviceRequestEmails">
          <a-select mode="tags" v-model:value="form.sendApprovedOrderDeviceRequestEmails" placeholder="please input">
          </a-select>
        </a-form-item>
      </a-form>

      <div>
        <a-button key="submit" size="large" type="primary" :loading="loading" @click="onSubmit">
          Save setting
        </a-button>
      </div>
    </div>
  </a-spin>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { message } from 'ant-design-vue'
import { IOrganization, IOrganizationLeaveSetting, IUser } from '@/utils/types'

import FireOrganization from '@/services/organization'

@Options({
  components: {},
  directives: { maska },
  emits: [],
})
export default class OrganizationLeaveSettingForm extends Vue {
  loading = false
  organization: IOrganization = {}

  requiredRule = {
    required: true,
    message: 'This field is required',
    trigger: 'blur',
  }

  form: IOrganizationLeaveSetting = {
    sendNewLeaveRequestToEmails: [],
    sendApprovedLeaveRequestEmails: [],
    sendApprovedOrderDeviceRequestEmails: [],
  }

  rules = {}

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(() => {
        this.saveSetting()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  async saveSetting() {
    await FireOrganization.save({ id: this.organization.id, leavesSetting: this.form })
    message.success('Saved successfully')
  }

  async created() {
    this.organization = (await FireOrganization.get()) || {}

    this.form = { ...this.form, ...this.organization.leavesSetting }
  }
}
</script>
